<template>
    <div class="dashboard-common-filters" id="bankDashboardCountriesFilters">
        <div class="dashboard-common-filters__top">
            <div class="dashboard-common-filters__top--title">
                <span>{{ $t("Filters") }}</span>
                <a v-if="showFilterBadges" @click.prevent="setFiltersDefaultState" href="">{{ $t("Clear All") }}</a>
            </div>

            <FiltersScoreImportanceInfo />
        </div>

        <div class="section-card">
            <div class="dashboard-common-filters__card">
                <div class="dashboard-common-filters__item">
                    <div class="dashboard-common-filters__item--label">{{ $t("Customers") }}</div>

                    <SelectBoxWithModal
                        v-model="filterData.customers"
                        multiple
                        show-badge
                        :options="filterAllData.all_customers"
                        :all-selected-text="$t(filterAllSelectedText(BankFiltersSelectTypeEnum.CUSTOMERS))"
                        :custom-selection-text="$t(filterCustomSelectionText(BankFiltersSelectTypeEnum.CUSTOMERS))"
                        :placeholder="$t('Select Customers')"
                        @onTouch="isFilterTouched = true"
                    />
                </div>
                <div class="dashboard-common-filters__item">
                    <div class="dashboard-common-filters__item--label">{{ $t("Time Period") }}</div>

                    <DateRangePicker
                        @onTouch="isFilterTouched = true"
                        v-model="filterData.time_period"
                        :all-time-selection-value="getBankDefaultTimePeriod"
                    />
                </div>
                <div class="dashboard-common-filters__item">
                    <div class="dashboard-common-filters__item--label">{{ $t("Customer Tier") }}</div>

                    <SelectBoxWithModal
                        v-model="filterData.tiers"
                        multiple
                        show-badge
                        :options="filterAllData.all_tiers"
                        :all-selected-text="$t(filterAllSelectedText(BankFiltersSelectTypeEnum.CUSTOMER_TIER))"
                        :custom-selection-text="$t(filterCustomSelectionText(BankFiltersSelectTypeEnum.CUSTOMER_TIER))"
                        :placeholder="$t('Select Tiers')"
                        @onTouch="isFilterTouched = true"
                    />
                </div>
                <div class="dashboard-common-filters__item">
                    <div class="dashboard-common-filters__item--label">{{ $t("Customer’s Global Headquarter") }}</div>

                    <SelectBoxWithModal
                        v-model="filterData.customers_hq"
                        multiple
                        show-badge
                        self-value
                        :options="filterAllData.all_customers_hq"
                        :all-selected-text="$t(filterAllSelectedText(BankFiltersSelectTypeEnum.CUSTOMERS_GLOBAL_HQ))"
                        :custom-selection-text="$t(filterCustomSelectionText(BankFiltersSelectTypeEnum.CUSTOMERS_GLOBAL_HQ))"
                        :placeholder="$t('Select Countries')"
                        @onTouch="isFilterTouched = true"
                    />
                </div>
                <div class="dashboard-common-filters__item">
                    <div class="dashboard-common-filters__item--label">{{ $t("Industries") }}</div>

                    <SelectBoxWithModal
                        v-model="filterData.industries"
                        multiple
                        show-badge
                        self-value
                        :options="filterAllData.all_industries"
                        :all-selected-text="$t(filterAllSelectedText(BankFiltersSelectTypeEnum.INDUSTRIES))"
                        :custom-selection-text="$t(filterCustomSelectionText(BankFiltersSelectTypeEnum.INDUSTRIES))"
                        :placeholder="$t('Select Industries')"
                        @onTouch="isFilterTouched = true"
                    />
                </div>
                <div class="dashboard-common-filters__item">
                    <div class="dashboard-common-filters__item--label">{{ $t("Feedback countries of the bank") }}</div>

                    <SelectBoxWithModal
                        v-model="filterData.countries"
                        multiple
                        show-badge
                        self-value
                        :options="filterAllData.all_countries"
                        :all-selected-text="$t(filterAllSelectedText(BankFiltersSelectTypeEnum.COUNTRIES))"
                        :custom-selection-text="$t(filterCustomSelectionText(BankFiltersSelectTypeEnum.COUNTRIES))"
                        :placeholder="$t('Select Countries')"
                        @onTouch="isFilterTouched = true"
                    />
                </div>
                <div class="dashboard-common-filters__item apply-section">
                    <button @click="handleApplyFilters" class="main-btn btn apply-section__btn">
                        {{ $t("Apply") }}

                        <span
                            v-show="isFilterTouched && !isFilterApplied"
                            class="apply-section__btn--badge"
                        >
                            <BusinessFilterAppliedBadges
                                :filterData="filterData"
                                :allData="filterAllData"
                                :forceShow="showFilterBadges === 0"
                                @getSize="handleGetSize"
                            />
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FiltersScoreImportanceInfo from "@/buying-teams/shared-components/utils/FiltersScoreImportanceInfo";
import SelectBoxWithModal from "@/buying-teams/shared-components/inputs/SelectBoxWithModal";
import store from "@/store";
import BusinessFilterAppliedBadges from "@/buying-teams/shared-components/filter/BusinessFilterAppliedBadges";
import DateRangePicker from "@/buying-teams/shared-components/date-time/DateRangePicker";
import { filterAllSelectedText, filterCustomSelectionText } from "@/core/helpers/FiltersHelper";
import { BankFiltersSelectTypeEnum } from "@/store/enums/bank/BankFiltersSelectTypeEnum";

export default {
    name: "BankDashboardCountriesFilters",
    components: {
        DateRangePicker,
        BusinessFilterAppliedBadges,
        FiltersScoreImportanceInfo,
        SelectBoxWithModal,
    },
    emits: ['applyFilters'],
    data() {
        return {
            isFilterTouched: false,
            showFilterBadges: false,
            isReady: false,
            filterData: {
                customers: [],
                time_period: null,
                tiers: [],
                customers_hq: [],
                industries: [],
                countries: [],
            },
            isFilterApplied: true,
            filterAllSelectedText,
            filterCustomSelectionText,
            BankFiltersSelectTypeEnum,
        };
    },
    created() {
        this.setFilterData(this.countriesFilterData);
    },
    computed: {
        getBankDefaultTimePeriod() {
            return store.getters.getBankDefaultTimePeriod;
        },
        countriesFilterData() {
            return store.getters.countriesDashboardFilter;
        },
        countriesDashboardFilter() {
            return store.getters.getBankFiltersState.countries;
        },
        filterAllData() {
            return {
                all_customers: this.countriesDashboardFilter.customers,
                all_time_period: this.countriesDashboardFilter.time_period,
                all_tiers: this.tiers,
                all_customers_hq: this.countriesDashboardFilter.customers_hq,
                all_industries: this.countriesDashboardFilter.industries,
                all_countries: this.countriesDashboardFilter.countries,
            };
        },
        timePeriods() {
            return store.getters.timePeriods;
        },
        tiers() {
            return this.countriesDashboardFilter.tiers.map((item) => {
                return {
                    name: `${this.$t('Tier')} ${item}`,
                    id: item
                }
            });
        },
    },
    methods: {
        setFiltersDefaultState() {
            this.isFilterApplied = false;
            this.isFilterTouched = false;
            this.filterData = {
                customers: this.filterAllData.all_customers.map(c => c.id),
                time_period: this.filterAllData.all_time_period,
                tiers: this.filterAllData.all_tiers.map(t => t.id),
                customers_hq: this.filterAllData.all_customers_hq,
                industries: this.filterAllData.all_industries,
                countries: this.filterAllData.all_countries,
            };
            this.$emit('applyFilters', this.filterData)
        },
        handleApplyFilters() {
            this.isFilterApplied = true;
            this.$emit('applyFilters', this.filterData);
        },
        setFilterData(newFilters) {
            for (let [key, value] of Object.entries(newFilters)) {
                this.filterData[key] = value;
            }
        },
        handleGetSize(touchedCount) {
            this.isFilterApplied = false;
            this.showFilterBadges = touchedCount;
        }
    }
}
</script>

<style lang="scss">
#bankDashboardCountriesFilters {
    .section-card {
        padding: 14px 14px 8px 10px;
    }
    .dashboard-common-filters__card {
        padding: 0;
    }
    .dashboard-common-filters__item {
        .el-select, .el-input, .el-input__inner {
            width: 100%;
        }
    }
    .apply-section__btn {
        margin-top: 0;
    }
}
</style>
