<template>
    <div class="platform-dashboard-pages stats-collapse-countries">
        <div class="platform-dashboard-pages__filter" v-if="!filterLoading">
            <BankDashboardCountriesFilters
                ref="bankDashboardCountriesFilters"
                @applyFilters="fetchCountriesData"
            />
        </div>

        <div v-if="dashboardCountryContainer" class="platform-dashboard-pages__content">
            <DashboardCommonHeader
                class="mb-5"
                :title="$t('Countries')"
                :description="$t('Click on any of the country from the list for more details on it')"
                :count="dashboardCountryContainer.totalScore.count"
                :add-button-text="$t('Manage Countries')"
                title-icon="/media/buying/icons/map-black-icon.svg"
                @onSortChange="handleSortChange"
                @onClickAdd="handleClickAdd"
            />

            <DashboardStatsTable
                :score-title="$t('Company Score')"
                :score-text="$t('Based on all your Country/filter applied')"
                :total-score="dashboardCountryContainer.totalScore"
            >
                <template #content>
                    <div class="stats-collapse">
                        <template v-if="dashboardCountryContainer.containerData.length">
                            <transition-group name="group-transition">
                                <div :key="item.item.name" v-for="item of dashboardCountryContainer.containerData">
                                    <DashboardStatsCollapseTableItem
                                        :containerItem="item"
                                        :filterData="$refs.bankDashboardCountriesFilters.filterData"
                                        :pageType="BankPageTypes.COUNTRY"
                                    />
                                </div>
                            </transition-group>
                        </template>
                        <div class="section-card section-card__border" v-else>
                            <DashboardEmptyState
                                icon="/media/buying/icons/empty-state-country.svg"
                                :title="$t('No Country Found')"
                                :text="$t('Your company don\'t have any data for the countries selected. Create a feedback session to start reviewing and get insights into your banks performance.')"
                                :button-text="$t('Add Country')"
                                button-link="/business/settings/user-settings/overview"
                                button-icon="/media/buying/icons/plus.svg"
                                class-name="mb-10"
                            />
                        </div>
                    </div>
                </template>
            </DashboardStatsTable>
        </div>

        <div class="h-50-vh" v-if="filterLoading || !dashboardCountryContainer">
            <CustomLoader />
        </div>
    </div>
</template>

<script>
import store from "@/store";
import { diagLog } from "@/core/helpers/GlobalHelper";
import { BankPageTypes } from "@/store/enums/bank/BankPageTypes";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { BankDashboardCountriesContainer } from "@/store/models/bank/dashboard/BankDashboardCountriesContainer";
import DashboardCommonHeader from "@/buying-teams/shared-components/dashboard/DashboardCommonHeader";
import BankDashboardCountriesFilters from "@/buying-teams/pages/bank/dashboard/components/BankDashboardCountriesFilters";
import DashboardStatsTable from "@/buying-teams/shared-components/dashboard/DashboardStatsTable";
import DashboardStatsCollapseTableItem from "@/buying-teams/shared-components/dashboard/DashboardStatsCollapseTableItem";
import DashboardEmptyState from "@/buying-teams/shared-components/dashboard/DashboardEmptyState";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import BankFiltersTypeEnum from "@/store/enums/bank/BankFiltersTypeEnum";

export default {
    name: "Countries",
    components: {
        CustomLoader,
        DashboardEmptyState,
        DashboardStatsCollapseTableItem,
        DashboardStatsTable,
        BankDashboardCountriesFilters,
        DashboardCommonHeader
    },
    data() {
        return {
            filterLoading: true,
            dashboardCountryContainer: null,
            BankPageTypes
        }
    },
    computed: {
        bank() {
            return store.getters.bank;
        },
        countriesDashboardFilter() {
            return store.getters.countriesDashboardFilter;
        },
    },
    async mounted() {
        setCurrentPageBreadcrumbs({
            title: this.bank.name,
            logo: this.bank.icon_path
        });

        await store.dispatch('initFiltersData', { type: BankFiltersTypeEnum.COUNTRIES })
        this.filterLoading = false;

        await this.fetchCountriesData(this.countriesDashboardFilter);
    },
    methods: {
        handleSortChange(sorting) {
            this.dashboardCountryContainer.sort(sorting);
        },
        handleClickAdd() {
            this.$router.push("/bank/settings/user-settings/overview");
        },
        async fetchCountriesData(filter = null) {
            try {
                diagLog("filter ", filter);
                this.dashboardCountryContainer = null;
                let res = await store.dispatch("fetchBankDashboardDataLevelTwo", {filter, type: BankPageTypes.COUNTRY});
                this.dashboardCountryContainer = new BankDashboardCountriesContainer(res);
                diagLog("fetchProductAreasData DATA", res);
                diagLog("fetchProductAreasData MODEL", this.dashboardCountryContainer);
            } catch (e) {
                console.error(e);
            }
        },
    }
}
</script>

<style lang="scss">
.stats-collapse-countries {
    .stats-values {
        margin-right: 50px;
    }
    .stats-table__header--headings {
        margin-right: 66px;
    }
}
</style>
